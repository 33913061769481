'use client';

import PrimeryCard from '@/components/core/PrimeryCard';
import SectionWithHeading from '@/components/core/SectionWithHeading';
import SliderWrapper from '@/components22/common/Slider';
import './topdestinations.css';

const TopDestinations = ({ topThingsToDoWorldWide }) => {
    return (
        <SectionWithHeading
            title={'Top destination'}
            className={'relative z-0'}
        >
            <SliderWrapper className={'mt-2 !px-4'}>
                {topThingsToDoWorldWide
                    ?.slice()
                    .reverse()
                    .map(({ _id, tour_name: title, images, destination }) => (
                        <PrimeryCard
                            key={_id}
                            title={title}
                            img={images[0]}
                            imgAlt={'img_of_' + title}
                            location={destination.name}
                        />
                    ))}
            </SliderWrapper>
        </SectionWithHeading>
    );
};

export default TopDestinations;
