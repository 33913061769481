import React from "react";
import ImageWithPlaceholder from "../../Skeleton/ImageWIthPlacholder";
import styles from "@/styles/banner.module.css";
import clsx from "clsx";
const BannerOfferItem = ({
  title,
  offerStatement,
  offerButton,
  backgroundImage,
  color,
}) => {
  return (
    <div className="md:p-0 relative rounded-3xl overflow-hidden mx-2">
      <ImageWithPlaceholder
        src={backgroundImage}
        className={styles.banner_item}
        alt={""}
        width={10}
        height={6.64}
      ></ImageWithPlaceholder>
      <div
        className={clsx(
          "absolute top-0 left-0 w-full p-6 bg-[rgba(0,0,0,0.3)] h-full",
          color === "white" ? "text-white" : " text-gray-700"
        )}
      >
        <h2
          className={clsx(
            "text-base font-medium",
            color === "white" ? "text-white" : " text-gray-700"
          )}
        >
          {title}
        </h2>
        <div className="mt-3">{offerStatement}</div>
        <div className="mt-4">{offerButton}</div>
      </div>
    </div>
  );
};

export default BannerOfferItem;
