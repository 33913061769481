'use client';

import { BannerSlider } from '@/components/Carousel';
import ImageWithPlaceholder from '@/components22/common/Skeleton/ImageWIthPlacholder';
import styles from '@/styles/banner.module.css';
const Banner = ({ banners }) => {
    // console.log(activeCarouselRef);
    const carousels = [
        'https://img.freepik.com/free-vector/realistic-travel-agency-template-design_23-2149340532.jpg?w=1060&t=st=1721823307~exp=1721823907~hmac=5f5d180859e07b599c8846847e34fd00254b2afdd53df04e152a547e64ee8b5b',
        'https://img.freepik.com/free-vector/flat-design-travel-agency-twitter-header-with-map_23-2149366308.jpg?w=1060&t=st=1721823345~exp=1721823945~hmac=738a0dfc8a7af56c99e25f16fe90374d887aa8527794da982e5efaf4ae381cd1',
        'https://img.freepik.com/free-vector/organic-flat-hotel-banner-with-photo_23-2148926523.jpg?t=st=1721823436~exp=1721827036~hmac=c9843923e3dd1d7b3985ca52cf0d024c92650f260bd3992d6797b5547dfeaf65&w=1060',
        'https://images.unsplash.com/photo-1469854523086-cc02fe5d8800?q=80&w=2021&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        'https://www.travelandleisure.com/thmb/20XiUM8junt9cadIa9DzlV3seiw=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/charleston-south-carolina-18-BESTCITIESWB21-7d3bc086de98456bbcb77142e9d72892.jpg',
    ];

    // h-[170px] sm:h-[300px] md:h-[400px] w-full rounded-[1.75rem] md:rounded-none object-cover
    if (false && banners.length > 0) {
    } else {
        return (
            <div className="mb-2">
                <BannerSlider>
                    {carousels.map((bannerItem) => (
                        <div key={bannerItem} className="md:p-0 px-2 relative">
                            <ImageWithPlaceholder
                                src={bannerItem}
                                className={styles.banner_item}
                                alt={bannerItem}
                                width={10}
                                height={6.64}
                            ></ImageWithPlaceholder>
                        </div>
                    ))}
                </BannerSlider>
            </div>
        );
    }
};

export default Banner;
