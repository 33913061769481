"use client";

import { motion } from "framer-motion";
import React, { useState } from "react";
import BottomSheet from "../BottomSheet";
import useFullScreen from "@/hooks/useFullScreen";
import clsx from "clsx";
import { useSearchName } from "@/hooks/searchParams";

const OpenAllCategories = ({ services }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openBottomSheet = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  useFullScreen(isModalOpen);
  return (
    <>
      <div
        className="w-fit px-4 py-2 rounded-xl flex items-center justify-center gap-x-2 mx-auto my-2 bg-[rgba(0,_0,_0,_0.03)] cursor-pointer"
        onClick={openBottomSheet}
      >
        <img
          src="/icons/more_category.png"
          className="w-[19.99px] h-[20px] object-cover  "
          style={{
            filter: `drop-shadow(0 3px 5px rgba(255, 97, 44, 0.4))`,
          }}
          alt="more"
        />
        <span className="giest_regular text-[12px] text-gray-600">
          All Categories
        </span>
      </div>
      <BottomSheet
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        height={"calc(100% - 30vh)"}
      >
        <div className="md:pl-6 flex flex-col pb-3 mt-3">
          {services
            ?.filter((elem) => elem.link)
            .map((tag) => (
              <MemoizedCategoryElem key={tag.name} {...tag} />
            ))}
        </div>
      </BottomSheet>
    </>
  );
};

const CategoryElem = ({
  name,
  subCategories,
  secIcon,
  iconImg,
  size,
  elemOnClick = () => {},
  elemClassName,
}) => {
  const category = useSearchName("category");

  return (
    <div className="flex md:items-center md:flex-row flex-col md:gap-x-1 text-sm justify-start pb-3 mb-3 ">
      <div className="flex justify-between flex-grow min-w-[100px] md:max-w-[100px] px-6 md:px-0 py-2 md:py-4">
        <div className="flex gap-x-3 items-center">
          <img src={secIcon} className="w-[20] h-[20px] "></img>
          <h2 className="giest_light">{name}</h2>
        </div>
        {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                    className="hidden md:block w-[20px] text-gray-600 -rotate-90"
                >
                    <path
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M5.293 9.293a1 1 0 0 1 1.414 0L12 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414"
                        clipRule="evenodd"
                    />
                </svg> */}
      </div>
      <motion.div className="overflow-hidden">
        <div className="flex gap-x-6 items-center pl-14 px-6 md:px-3 overflow-auto hide-scrollbar pt-1 md:pt-3 py-3">
          {subCategories?.map((type) => (
            <div
              key={type._id}
              className={clsx(
                "min-w-fit flex gap-x-2 items-center pr-3 p-1 bg-white shadow-md rounded-2xl cursor-pointer",
                elemClassName,
                category && type.name === category && "!bg-[#ff612c] text-white"
              )}
              onClick={() => elemOnClick(type)}
            >
              <div className="min-w-[28px] max-w-[28px] h-[28px] flex items-center justify-center bg-[rgba(0,0,0,0.03)] rounded-xl py-[3px]">
                <img
                  src={iconImg}
                  alt={type.name}
                  width={size.w}
                  height={size.h}
                  className={`min-w-[${size.w}] bg-gray-50  object-cover scale-75`}
                />
              </div>

              <p className="text-xs text-center giest_light">{type.name}</p>
            </div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export const MemoizedCategoryElem = React.memo(CategoryElem);

export default OpenAllCategories;
