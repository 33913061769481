import(/* webpackMode: "eager" */ "/vercel/path0/components/Carousel/ProductSlider.jsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/home/OpenAllCategories.jsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/home/TopThingsToDoWorldWidePosts.jsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Tabs.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components22/common/BrowseServicesByCountries/BrowseServicesByCountries.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components22/common/Offers/BannerOffers/BannerOffers.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components22/common/Slider/Slider.jsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components22/pages/home/Banners/Banner.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components22/pages/home/Sections/TopDestinations/TopDestinations.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components22/pages/home/WhereTo/WhereTo.jsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/primerycard.css");
