"use client";

import { useRef } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
// const SheetChildren = React.lazy(() => import("./SheetChildren"));
const HomeBottomSheet = ({
  isModalOpen,
  handleCloseModal,
  children,
  height,
}) => {
  const sheetRef = useRef();
  // const getElem = () =>  setTimeout(
  //   () => (
  //     <Suspense fallback="">
  //       <SheetChildren height={height}>{children}</SheetChildren>
  //     </Suspense>
  //   ),
  //   40
  // );
  // console.log(isModalOpen);
  return (
    <>
      {/* <AnimatePresence>
      {isModalOpen && (
        <>
          <BlackOverlay handleCloseModal={handleCloseModal}></BlackOverlay>
          <div className="flex justify-center">
            <SheetChildren height={height}>{children}</SheetChildren>
          </div>
        </>
      )}
    </AnimatePresence> */}
      <BottomSheet
        open={isModalOpen}
        onDismiss={handleCloseModal}
        defaultSnap={({ snapPoints, lastSnap }) => lastSnap ?? snapPoints[1]}
        snapPoints={({ maxHeight }) => [
          maxHeight, // full screen (maxHeight)
          maxHeight * 0.88, // 70vh
        ]}
        // initialSnap={2}
        className="relative z-[99999] !select-text"

      >
        {children}
      </BottomSheet>
    </>
  );
};

export default HomeBottomSheet;
