import '@/styles/primerycard.css';

const PrimeryCard = ({ title, location, img, imgAlt }) => {
    return (
        <div
            className="rounded-3xl p-2 min-w-[160px] w-[160px] max-w-[160px] md:w-[186px] md:min-w-[176px] mb-2"
            style={{ boxShadow: 'var(--primery-card-shadow)' }}
        >
            {/* Image Part */}
            <div className="rounded-2xl trend_posts_shadow">
                <img
                    src={img}
                    alt={imgAlt}
                    className="w-full h-[146px] md:h-[136px] select-none object-cover rounded-2xl relative z-50"
                />
            </div>

            {/* Content part */}
            <div className="grid grid-cols-8 pl-2 pr-1 pb-1 !items-center justify-between mt-3">
                <div className="col-span-6">
                    <h2 className="text-sm giest_regular text-gray-900 line-clamp-1">
                        {title}
                    </h2>
                    <div className="text-xs giest_light text-gray-400 flex items-center gap-x-1 mt-1 GTE_regular">
                        {/* Location SVG */}
                        <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                            className="text-xs text-[#ff612c]"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                        <p className="mt-[2px]">{location}</p>
                    </div>
                </div>
                {/* Heart Division */}
                <div className="col-span-2">
                    <div
                        className="w-[22px] h-[22px] p-1 bg-white flex items-center justify-center rounded-full cursor-pointer"
                        style={{
                            boxShadow: '-2px 4px 4px rgba(255, 97, 44, 0.2)',
                        }}
                    >
                        {/* Heart Icon */}
                        <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 256 256"
                            className="text-[#ff612c]"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M178,42c-21,0-39.26,9.47-50,25.34C117.26,51.47,99,42,78,42a60.07,60.07,0,0,0-60,60c0,29.2,18.2,59.59,54.1,90.31a334.68,334.68,0,0,0,53.06,37,6,6,0,0,0,5.68,0,334.68,334.68,0,0,0,53.06-37C219.8,161.59,238,131.2,238,102A60.07,60.07,0,0,0,178,42ZM128,217.11C111.59,207.64,30,157.72,30,102A48.05,48.05,0,0,1,78,54c20.28,0,37.31,10.83,44.45,28.27a6,6,0,0,0,11.1,0C140.69,64.83,157.72,54,178,54a48.05,48.05,0,0,1,48,48C226,157.72,144.41,207.64,128,217.11Z"></path>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrimeryCard;
