"use client";
import SliderWrapper, { tourCardSetting } from "@/components22/common/Slider";
import { useSearchParams } from "next/navigation";
import ActivityCard from "../core/ActivityCard";
import "./topTopThingsToDoWorldWide.css";
import { useQuery } from "react-query";
import { getAllTours } from "@/services/operations/tours";
const TopThingsToDoWorldWidePosts = ({
  topThingsToDoWorldWide,
  firstDestination,
}) => {
  const searchParams = useSearchParams();
  const country = searchParams.get("country");
  const {data:allTours,status,isFetching} = useQuery({
    queryKey: ["topthingstodoworldwide"],
    queryFn: getAllTours,
    staleTime: Infinity,
  });
  console.log(allTours)
  return (
    <SliderWrapper
      settings={tourCardSetting}
      className={"relative mt-2 !px-4 sm:mt-3 md:!gap-4"}
    >
      {!isFetching && allTours
        ?.filter((tour) => {
          if (country) {
            if (tour.destination.name === country) {
              return tour;
            }
          } else {
            if (tour.destination.name === firstDestination.name) {
              return tour;
            }
          }
        })
        .map((tour) => (
          <ActivityCard key={tour._id} {...tour} />
        ))}
        {
            isFetching && "loading..."
        }
      {/* {Array.apply( {tour:{
        destination:"name"
      }} , Array(20))
        .map((item,index) => (
          <div key={index} className="min-w-[220px] w-[220px] h-[250px] bg-[#f4f4f4] rounded-2xl product-card">
            <h1>{index+1}</h1>
          </div>
        ))} */}
    </SliderWrapper>
  );
};

export default TopThingsToDoWorldWidePosts;
