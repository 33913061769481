import Link from "next/link";
import { HeartLikeIcon } from "../svgs/Icons";
import RatingStars from "./RatingStars";

const ActivityCard = ({
  _id,
  price,
  tour_name,
  destination,
  description,
  currency,
  images,
  ratings = 5,
  tour_type,
}) => {
  return (
    <Link href={`/tours/${_id}`}>
      {" "}
      <div className="min-w-[156px] w-[156px] sm:w-[190px] md:min-w-[216px] md:w-[216px] rounded-xl overflow-hidden relative shadow-lgg ">
        <div className="absolute top-2 left-3">
          <div className="bg-[#ff612c] text-white text-[10px] rounded-full px-1 GTE_light flex gap-x-1 items-center">
            {/* Price tag */}
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 512 512"
              className="text-[9px] text-white"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M467 45.2A44.45 44.45 0 0 0 435.29 32H312.36a30.63 30.63 0 0 0-21.52 8.89L45.09 286.59a44.82 44.82 0 0 0 0 63.32l117 117a44.83 44.83 0 0 0 63.34 0l245.65-245.6A30.6 30.6 0 0 0 480 199.8v-123a44.24 44.24 0 0 0-13-31.6zM384 160a32 32 0 1 1 32-32 32 32 0 0 1-32 32z"></path>
            </svg>{" "}
            {tour_type?.name}
          </div>
        </div>
        <div className="w-full">
          <img
            loading="lazy"
            src={images && images[0]}
            alt="tour Img"
            className="w-full h-[120px] sm:h-[160px] object-cover rounded-2xl drop-shadow-sms"
          />
        </div>
        <div className="px-3 mt-2">
          <span className="text-[11px] text-gray-800 GTE_light ">
            {destination.name}
          </span>
          {/* <Tooltip content={tour_name}> */}
          <h3 className="text-sm sm:text-base font-medium line-clamp-2">
            {tour_name} Abu Dhabi Dubai safari tour
          </h3>
          {/* </Tooltip> */}

          <div className="flex items-center justify-between flex-wrap sm:flex-nowrap sm:mt-1">
            {/* <p className="text-[8px] text-richblack-200 flex items-center mt-1">277 Reviews</p> */}

            <div>
              <div className="flex mt-1 sm:mt-1 gap-x-1 items-center">
                {/* <FaStar className="text-yellow-400 text-[8px]"></FaStar>
                <FaStar className="text-yellow-400 text-[8px]"></FaStar>
                <FaStar className="text-yellow-400 text-[8px]"></FaStar>
                <FaStar className="text-yellow-400 text-[8px]"></FaStar>
                <FaStar className="text-yellow-400 text-[8px]"></FaStar> */}
                <RatingStars ratings={5}></RatingStars>
              </div>
              <h3 className="text-[13px] font-medium text-richblack-900 mt-2">
                {currency?.symbol} {price?.sharing_price}
              </h3>
            </div>
            <div
              className="w-[22px] h-[22px] p-1 bg-white flex items-center justify-center rounded-full cursor-pointer"
              style={{
                boxShadow: "-2px 4px 4px rgba(255, 97, 44, 0.2)",
              }}
            >
              {/* <PiHeartLight className="text-[#ff612c]"></PiHeartLight> */}
              <HeartLikeIcon></HeartLikeIcon>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ActivityCard;
