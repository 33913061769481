"use client";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./carousel.css";

const BannerSlider = ({ children }) => {
  // const query = useMemo(() => `(max-width:647px)`, []);

  const carouselProperties = {
    className: "banner_slider",
    infinite: true,
    centerMode: true,
    centerPadding: "20px",
    autoplay: true,
    pauseOnHover: false,
    touchThreshold: 150,
  };
  // useEffect(() => {
  //   const slickList = document.querySelector(".slick-list");
  //   if (sliderRef.current) {
  //     if (typeof window !== "undefined") {
  //       const isSmScrn = window.matchMedia("(max-width:647px)").matches;
  //       if (isSmScrn) {
  //         // if (sliderRef.current.props.centerPadding !== "30px") {
  //         // sliderRef.current.props.centerPadding = "30px";
  //         // }
  //       } else {
  //         if (
  //           window.getComputedStyle(slickList).padding.split(" ")[1] != "0px"
  //         ) {
  //           // console.log("in web")
  //           // sliderRef.current.props.centerPadding = "0px";
  //         }
  //       }
  //     }
  //   }
  // }, []);
  // console.log(isSmallScreen);
  // console.log(window.matchMedia(query).matches);

  // const c = useMemo( () => isSmallScreen , [isSmallScreen]);

  // useEffect(() => {
  //   if (isSmallScreen) {
  //     setSetting(carouselProperties);
  //   } else {
  //     setSetting(carouselProperties2);
  //   }
  // }, [isSmallScreen,carouselProperties,carouselProperties2]);
  return (
    <div className="w-full">
      <Slider {...carouselProperties}>
        {children}
      </Slider>
      {/* <button onClick={() => setInc((prev) => prev + 1)}> {inc} Inc</button> */}
    </div>
  );
};

export default BannerSlider;
