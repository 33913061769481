"use client";
import { BannerSlider } from "@/components/Carousel";
import BannerOfferItem from "./BannerOfferItem";

const BannerOffers = () => {
  return (
    <div className="mt-4">
      <BannerSlider>
        {/*  */}
        {/* <div className="md:p-0 px-2 relative">
          <ImageWithPlaceholder
            src={"https://img.freepik.com/premium-photo/meditation-man-stone_81048-36358.jpg"}
            className={styles.banner_item}
            alt={""}
            width={10}
            height={6.64}
          ></ImageWithPlaceholder>
        </div> */}
        <BannerOfferItem
          title={"Stay safe from Mosquito"}
          color="white"
          offerStatement={
            <p className="text-sm text-white">
              Up to <b>20% OFF</b> on <br /> inspect repellent
            </p>
          }
          backgroundImage={
            "https://img.freepik.com/free-photo/anime-style-character-meditating-contemplating-mindfulness_23-2151476693.jpg"
          }
          offerButton={<button>Visit</button>}
        ></BannerOfferItem>
        <BannerOfferItem
          title={"Stay safe from Mosquito"}
          color="white"
          offerStatement={
            <p className="text-sm text-white">
              Up to <b>20% OFF</b> on <br /> inspect repellent
            </p>
          }
          backgroundImage={
            "https://img.freepik.com/premium-photo/meditation-man-stone_81048-36358.jpg"
          }
          offerButton={<button>Visit</button>}
        ></BannerOfferItem>
      </BannerSlider>
    </div>
  );
};

export default BannerOffers;
