
import { IconSettings } from "@/components22/Svgs/svg-imports";
import React from "react";

const WhereToActionBar = ({ handleOpenBottomSheet }) => {
  return (
    <div className="px-4 pb-3 flex justify-center">
      {/* visible area  */}
      <div
        className={`rounded-full bg-white border-gray-300 flex items-center justify-between shadow-[0_0_10px_rgba(0,_0,_0,_0.09)] cursor-pointer w-full max-w-[400px] md:fixed md:max-w-[340px] md:rounded-full md:shadow-2xl md:bottom-8 md:left-1/2 md:-translate-x-1/2 md:z-50 md:py-2 md:px-4 transition-all duration-1000 md:border md:hidden`}
        onClick={handleOpenBottomSheet}
      >
        {/* Going Where Heading */}
        <div className="pl-8">
          <h2 className="text-sm GTE_regular">Yo! Going where?</h2>
          <div className="text-[11px] text-gray-500 font-normal gap-x-2 flex items-center">
            <span className="giest_light">Destination</span>
            <span className="inline-block w-[2px] h-[2px] bg-gray-500 rounded-full"></span>
            <span className="giest_light">Type</span>
            <span className="inline-block w-[2px] h-[2px] bg-gray-500 rounded-full"></span>
            <span className="giest_light">{"Let’s Go!"}</span>
          </div>
        </div>

        {/* Setting Icon */}
        <div className="py-2 pr-2 flex items-center">
          <div className="bg-white border rounded-full w-[40px] h-[40px] flex justify-center items-center">
            <IconSettings />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(WhereToActionBar);
