"use client";
import Link from "next/link";
import React, { useState } from "react";

const BrowseServicesByCountries = ({ countries, services }) => {
  const [activeTab, setActiveTab] = useState(countries && countries[0]); // Default to Australia
  return (
    <div className="p-4">
      {/* Tab Navigation */}
      <div className="flex space-x-4 overflow-auto">
        {countries?.map((country) => (
          <button
            key={country.name}
            onClick={() => setActiveTab(country)}
            className={`px-3 py-1 rounded-full font-giest-medium text-sm transition-all duration-300 ${
              activeTab?.name === country.name
                ? "bg-[#FF612C] text-white"
                : "bg-transparent text-black"
            }`}
          >
            {country.name}
          </button>
        ))}
      </div>

      {/* Content for the selected country */}
      <div className="mt-5 flex space-x-20 overflow-auto">
        {activeTab?.cities?.map((city) => (
          <div key={city.name} className="min-w-max">
            {activeTab.cities.length > 0 ? (
              <div key={city.name}>
                <h3 className="font-giest-light text-base mb-2 pb-2">{city.name}</h3>
                <ul className="space-y-2">
                  {services?.map((service) => (
                    <Link href={`/${service.name.split(" ").join("").toLowerCase()}?country=${activeTab.name}&city=${city.name}`} key={service._id} className="block text-sm font-giest-light text-neutral-600 hover:text-neutral-900 border rounded-full py-1 px-3 w-fit">
                      {service.name} in {city.name}
                    </Link>
                  ))}
                </ul>
              </div>
            ) : (
              <p>No cities available for {city.name}.</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BrowseServicesByCountries;
