// components/ImageWithPlaceholder.js
"use client";
import { useState } from "react";
import Image from "next/image";
import styles from "./ImageWithPlaceholder.module.css";

const ImageWithPlaceholder = ({ src, alt, className, width, height }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <div className={`relative ${className} overflow-hidden`}>
      <Image
        loader={() => src}
        src={src}
        alt={alt}
        layout="responsive"
        width={width}
        height={height}
        className={`${isLoaded ? styles.imageLoaded : styles.imageLoaded}`}
        onLoadingComplete={() => setIsLoaded(true)}
        // loading="lazy"
      />
      {!isLoaded && (
        <div className={styles.placeholder}>
          <Image
            alt="amfeza"
            src={"/profile/amfeza-gray-scale.png"}
            layout="responsive"
            className="w-[180px] md:w-[220px]"
            width={12}
            height={4}
          ></Image>
        </div>
      )}
    </div>
  );
};

export default ImageWithPlaceholder;
