"use client";
import { Suspense, useEffect, useState, lazy } from "@/index";
import HomeBottomSheet from "./HomePageBottomSheet";
import WhereToActionBar from "./WhereToActionBar";
const WhereToBottomSheetChild = lazy(() => import("./WhereToBottomSheetChild"));

const WhereTo = () => {
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const delay = 1000;

  useEffect(() => {
    if (!isContentLoaded) {
      setLoading(true);
    } else {
      if (isSheetOpen) {
        setTimeout(() => setLoading(false), delay);
      }
    }
  }, [isSheetOpen,isContentLoaded]);

  const handleOpenBottomSheet = () => {
    setIsSheetOpen(true);
  };
  const closeBottomSheet = () => {
    setIsSheetOpen(false);
  };
  return (
    <div>
      <WhereToActionBar handleOpenBottomSheet={handleOpenBottomSheet} />
      <HomeBottomSheet
        isSheetOpen={isSheetOpen}
        setIsSheetOpen={setIsSheetOpen}
        isLoader={loading}
        setIsContentLoaded={setIsContentLoaded}
      >
        <Suspense>
          <WhereToBottomSheetChild
            isBottomSheetOpen={isSheetOpen}
            closeBottomSheet={closeBottomSheet}
          ></WhereToBottomSheetChild>
        </Suspense>
      </HomeBottomSheet>
    </div>
  );
};

export default WhereTo;
