import { apiConnector } from "../apiconnector";

// Helper function for fetching data with pagination
const fetchData = async (url, page = 1, limit = 10) => {
    let result;
    try {
        // Construct the API URL with pagination parameters
        let urlStr = `${url}?page=${page}&limit=${limit}`;
        // console.log(urlStr);
        // Make the API call using apiConnector
        const response = await apiConnector("GET", urlStr);
        // console.log(response)
        // Check the response status
        if (!response.status) {
            throw new Error(`Could Not Fetch Data from ${url}`);
        }
        // console.log(response) 
        // Extract and return the data
        result = response.data;
    } catch (error) {
        // Log any errors
        console.error(error);
    }
    return result;
}

export default fetchData;