import clsx from "clsx";
import PrimeryHeading from "./PrimeryHeading";
import Section from "./Section";

// Reusable Section with Heading Component
const SectionWithHeading = ({ title, children, className, headingClass }) => (
  <Section className={className}>
    {title && (
      <PrimeryHeading className={clsx("px-4 mt-3 md:mt-4 ",headingClass)}>{title}</PrimeryHeading>
    )}
    {children}
  </Section>
);

export default SectionWithHeading;
